import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  headers: {
    "x-token": localStorage.getItem("x-token"),
  },
});

export default api;
