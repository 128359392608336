import { useRef, useState } from "react";
import api from "../../lib/axios";
import CaretLeft from "../../assets/images/caret-left.png";
import NoItems from "../../assets/images/no_items.png";
import Delete from "../../assets/images/delete.png";
import { useSelector, useDispatch } from "react-redux";
import { toggle, close } from "../../lib/slices/sidebarSlice";
import {
  addSelectedItem,
  removeSelectedItem,
  removeItem,
  updateItem,
  clear,
} from "../../lib/slices/cartSlice";
import CartItem from "./CartItem";
import "./sidebar.scss";
import { useMap } from "react-leaflet";
import _ from "lodash";
import { Popover } from "@mui/material";
import ReactDatePicker from "react-datepicker";
import Caret from "../Search/Caret";
import dayjs from "dayjs";
import Check from "../../assets/images/check.png";

function Sidebar(props) {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { selectedItems } = useSelector((state) => state.cart);
  const sidebar = useSelector((state) => state.sidebar.value);
  const [hash, setHash] = useState(false);
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [dateIsOpen, setDateIsOpen] = useState(false);
  const [makePdf, setMakePdf] = useState(false);
  const map = useMap();
  const calendarRef = useRef(null);
  const equipmentPage = props.equipmentPage ?? false;

  const today = new Date();

  const handleChange = async (range) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);

    if (startDate && endDate) {
      _.each(selectedItems, (muppi) => {
        const newItem = {
          ...muppi,
          startDate: startDate ? startDate.toJSON() : null,
          endDate: endDate ? endDate.toJSON() : null,
        };
        dispatch(updateItem(newItem));
      });
    }
  };

  const orderedCart = {};

  for (let index = 0; index < cart.value.length; index++) {
    const item = cart.value[index];
    const key = `${dayjs(item.startDate)
      .set("seconds", 0)
      .set("hours", 0)
      .set("minutes", 0)
      .unix()} - ${dayjs(item.endDate)
      .set("seconds", 0)
      .set("hours", 0)
      .set("minutes", 0)
      .unix()}`;
    if (!orderedCart[key]) {
      orderedCart[key] = [];
    }
    orderedCart[key].push(item);
  }

  return (
    <div
      onMouseEnter={() => {
        map.doubleClickZoom.disable();
        map.dragging.disable();
        map.scrollWheelZoom.disable();
        map.touchZoom.disable();
        map.boxZoom.disable();
        map.keyboard.disable();
        if (map.tap) map.tap.disable();
      }}
      onMouseLeave={() => {
        map.doubleClickZoom.enable();
        map.dragging.enable();
        map.scrollWheelZoom.enable();
        map.touchZoom.enable();
        map.boxZoom.enable();
        map.keyboard.enable();
        if (map.tap) map.tap.enable();
      }}
    >
      <button
        className={"toggleCart " + (sidebar ? "open" : "")}
        onClick={() => dispatch(toggle())}
      >
        <div className="icon">
          <img src={CaretLeft} alt="Left" />
        </div>
        {!sidebar ? `Mostrar lista` : `Esconder lista`}
      </button>
      <div
        className={"sidebar_container " + (sidebar ? "open" : "")}
        id="calendarAnchor"
        variant="contained"
      >
        {!hash ? (
          <>
            <div className="sidebar_container__header">
              <div className="title">Equipamentos Adicionados</div>
            </div>
            {cart.value.length > 0 && (
              <div className="selection_options">
                <button
                  className="select_all"
                  onClick={() => {
                    _.each(cart.value, (muppi) => {
                      dispatch(addSelectedItem(muppi));
                    });
                  }}
                >
                  Selecionar Todos
                </button>
                {selectedItems.length > 0 && (
                  <button
                    className="clean_selection"
                    onClick={() => {
                      _.each(selectedItems, (muppi) => {
                        dispatch(removeSelectedItem(muppi));
                      });
                      setStartDate(false);
                      setEndDate(false);
                    }}
                  >
                    limpar seleção ({selectedItems.length})
                  </button>
                )}
              </div>
            )}
            {selectedItems.length > 0 && (
              <>
                <div id="cart-calendar" className="calendar" ref={calendarRef}>
                  <div
                    className="date start-date"
                    onClick={() => {
                      setDateIsOpen(!dateIsOpen);
                    }}
                  >
                    <div>
                      {startDate
                        ? dayjs(startDate).format("D MMM YYYY").toString()
                        : `Data de início`}
                    </div>
                    <div>
                      <Caret />
                    </div>
                  </div>
                  <div
                    className="date end-date"
                    onClick={() => {
                      setDateIsOpen(!dateIsOpen);
                    }}
                  >
                    <div>
                      {endDate
                        ? dayjs(endDate).format("D MMM YYYY").toString()
                        : `Data de fim`}
                    </div>
                    <div>
                      <Caret />
                    </div>
                  </div>
                  <Popover
                    className="search-popover"
                    open={dateIsOpen}
                    onClose={() => {
                      setDateIsOpen(false);
                    }}
                    anchorEl={document.getElementById("cart-calendar")}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <ReactDatePicker
                      minDate={today}
                      selected={startDate}
                      dateFormat="dd/MM/yyyy"
                      onChange={handleChange}
                      startDate={startDate}
                      endDate={endDate ? new Date(endDate) : false}
                      selectsRange
                      inline
                      isClearable
                    />
                  </Popover>
                </div>
                <button
                  className="remove-selected"
                  onClick={() => {
                    _.each(selectedItems, (muppi) => {
                      dispatch(removeSelectedItem(muppi));
                      dispatch(removeItem(muppi));
                    });
                    setStartDate(false);
                    setEndDate(false);
                  }}
                >
                  <div className="icon">
                    <img src={Delete} alt="delete" />
                  </div>{" "}
                  remover equipamentos ({selectedItems.length})
                </button>
              </>
            )}
            <div className="sidebar_container__body">
              {cart.value.length > 0 ? (
                <>
                  {_.map(orderedCart, (items, index) => {
                    const dates = index.split("-");
                    return (
                      <div key={Math.random() * 1000} className="date-item">
                        <div className="separator">
                          {dayjs.unix(dates[0].trim()).format("DD/MM/YYYY")} -{" "}
                          {dayjs.unix(dates[1].trim()).format("DD/MM/YYYY")}
                        </div>
                        <div className="items">
                          {items.map((item) => (
                            <CartItem item={item} key={Math.random() * 1000} />
                          ))}
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="no_pins">
                  <div className="message">
                    Ainda não existem Equipamentos adicionados à lista. Por
                    favor, explore o mapa.
                  </div>
                  <div className="image">
                    <img src={NoItems} alt="No items" />
                  </div>
                </div>
              )}
            </div>
            {cart.value.length > 0 && (
              <div className="sidebar__footer">
                <button
                  className={`checkbox_pdf ${makePdf ? "selected" : ""}`}
                  onClick={() => {
                    setMakePdf(!makePdf);
                  }}
                >
                  <div className="checkbox_pdf_icon">
                    <img src={Check} alt="check" />
                  </div>
                  Gerar PDF e anexar ao email
                </button>

                <button
                  className="submit_order"
                  onClick={() =>
                    sendOrder(cart.value, setHash, makePdf, equipmentPage)
                  }
                >
                  {`Submeter Equipamentos (${cart.count})`}
                </button>
              </div>
            )}
          </>
        ) : (
          <div className="res_hash">
            <div className="sidebar_container__header">
              <div className="title">Pedido Enviado</div>
            </div>
            <div className="sidebar_container__body">
              <div className="message">
                Pedido enviado com sucesso, pode verificar{" "}
                <a
                  href={`${window.location.origin}/${
                    equipmentPage ? "view-equipment" : "view"
                  }/${hash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  aqui
                </a>
                .
              </div>
              <div className="image">
                <img src={NoItems} alt="No items" />
              </div>
              <button
                className="new_order"
                onClick={() => {
                  dispatch(clear());
                  dispatch(close());
                  setStartDate(false);
                  setEndDate(false);
                  setDateIsOpen(false);
                  setHash(false);
                }}
              >
                Fazer novo pedido
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

async function sendOrder(
  pins,
  setHash,
  makePdf = false,
  equipmentPage = false
) {
  try {
    const { data } = await api.post(
      "/order",
      { pins, makePdf, equipmentPage },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    setHash(data.hash);
    return;
  } catch (err) {
    console.log(err);
  }
}
export default Sidebar;
