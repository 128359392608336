import { useState } from "react";
import "./search.scss";

import { useMap } from "react-leaflet";
import OptionSelect from "./OptionSelect";
import _ from "lodash";
import Caret from "./Caret";
import Popover from "@mui/material/Popover";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  setStartDate,
  setEndDate,
  setMapAdjust,
} from "../../lib/slices/searchSlice";
import { getMonopostes } from "../../lib/slices/monoposteSlice";
import { getOutdoors } from "../../lib/slices/outdoorSlice";
import { getSinaleticas } from "../../lib/slices/sinaleticaSlice";

export default function SearchEquipments(props) {
  const map = useMap();
  const dispatch = useDispatch();
  const initialDataMonopostes = useSelector(
    (state) => state.monoposte.initialValue
  );
  const initialDataOutdoors = useSelector(
    (state) => state.outdoor.initialValue
  );
  const initialDataSinaleticas = useSelector(
    (state) => state.sinaletica.initialValue
  );
  let initialData = [
    ...initialDataMonopostes,
    ...initialDataOutdoors,
    ...initialDataSinaleticas,
  ];
  const { startDate, endDate } = useSelector((state) => state.search);
  const [availability, setAvailability] = useState(false);
  const [params, setParams] = useState({});
  const [clear, setClear] = useState(false);
  const [dateIsOpen, setDateIsOpen] = useState(false);
  const [refSeries, setRefSeries] = useState(false);
  const [refNetworks, setRefNetworks] = useState(false);
  const [refEquipment, setRefEquipment] = useState(false);
  const [refSituacao, setRefSituacao] = useState(false);
  const [refDimensao, setRefDimensao] = useState(false);
  const [refConcelhos, setRefConcelhos] = useState(false);

  const today = new Date();

  const handleChange = async (range) => {
    const [startDate, endDate] = range;
    dispatch(setMapAdjust(true));
    dispatch(setStartDate(startDate ? startDate.toJSON() : null));
    dispatch(setEndDate(endDate ? endDate.toJSON() : null));

    if (startDate && endDate) {
      const newParams = {
        ...params,
        date: [startDate, endDate],
      };
      setParams(newParams);
      dispatch(getMonopostes({ params: newParams }));
      dispatch(getOutdoors({ params: newParams }));
      dispatch(getSinaleticas({ params: newParams }));
    } else if (startDate) {
      const newParams = {
        ...params,
        date: [
          startDate,
          new Date(
            startDate.getFullYear() + 2,
            startDate.getMonth(),
            startDate.getDay()
          ),
        ],
      };
      setParams(newParams);
      dispatch(getMonopostes({ params: newParams }));
      dispatch(getOutdoors({ params: newParams }));
      dispatch(getSinaleticas({ params: newParams }));
    }
  };

  return (
    <div
      className="search"
      onMouseEnter={() => {
        map.doubleClickZoom.disable();
        map.dragging.disable();
        map.scrollWheelZoom.disable();
        map.touchZoom.disable();
        map.boxZoom.disable();
        map.keyboard.disable();
        if (map.tap) map.tap.disable();
      }}
      onMouseLeave={() => {
        map.doubleClickZoom.enable();
        map.dragging.enable();
        map.scrollWheelZoom.enable();
        map.touchZoom.enable();
        map.boxZoom.enable();
        map.keyboard.enable();
        if (map.tap) map.tap.enable();
      }}
    >
      <div className="container">
        <div className="title">Filtrar Equipamentos</div>
        {/*Equipamento */}
        <div className="group">
          <OptionSelect
            onRef={(ref) => {
              setRefEquipment(ref);
            }}
            placeholder="Equipamento"
            options={[
              { value: "monoposte", label: "Monoposte" },
              { value: "outdoor", label: "Outdoor" },
              { value: "sinaletica", label: "Sinalética" },
            ]}
            onChangeChoices={async (data) => {
              if (!clear) {
                dispatch(setMapAdjust(true));
                const newParams = {
                  ...params,
                  equipment: data,
                };
                setParams(newParams);
                dispatch(getMonopostes({ params: newParams }));
                dispatch(getOutdoors({ params: newParams }));
                dispatch(getSinaleticas({ params: newParams }));
              }
            }}
          />
        </div>
        {/*Situação */}
        <div className="group">
          <OptionSelect
            onRef={(ref) => {
              setRefSituacao(ref);
            }}
            placeholder="Situação"
            options={[
              { value: "disponivel", label: "Disponivel" },
              { value: "rescindiu", label: "Rescindiu" },
              { value: "oferta", label: "Oferta" },
              { value: "fantasma", label: "Fantasma" },
              { value: "reserva", label: "Reserva" },
            ]}
            onChangeChoices={async (data) => {
              if (!clear) {
                dispatch(setMapAdjust(true));
                const newParams = {
                  ...params,
                  situacao: data,
                };
                setParams(newParams);
                dispatch(getMonopostes({ params: newParams }));
                dispatch(getOutdoors({ params: newParams }));
                dispatch(getSinaleticas({ params: newParams }));
              }
            }}
          />
        </div>
        {/*concelho */}
        {!_.isEmpty(initialData) && (
          <div className="group">
            <OptionSelect
              onRef={(ref) => {
                setRefConcelhos(ref);
              }}
              placeholder="Concelho"
              onChangeChoices={async (data) => {
                if (!clear) {
                  dispatch(setMapAdjust(true));
                  const newParams = {
                    ...params,
                    concelhos: data,
                  };
                  setParams(newParams);
                  dispatch(getMonopostes({ params: newParams }));
                  dispatch(getOutdoors({ params: newParams }));
                  dispatch(getSinaleticas({ params: newParams }));
                }
              }}
              options={_.sortBy(
                _.uniqBy(
                  initialData
                    .map((muppi) => {
                      return {
                        value: muppi.concelho,
                        label: muppi.concelho,
                      };
                    })
                    .filter((muppi) => muppi.value && muppi.value !== ""),
                  "value"
                ),
                "value"
              )}
            />
          </div>
        )}
        {/*serie */}
        {!_.isEmpty(initialData) && (
          <div className="group">
            <OptionSelect
              onRef={(ref) => {
                setRefSeries(ref);
              }}
              placeholder="Nr Serie"
              onChangeChoices={async (data) => {
                if (!clear) {
                  dispatch(setMapAdjust(true));
                  const newParams = {
                    ...params,
                    series: data,
                  };
                  setParams(newParams);
                  dispatch(getMonopostes({ params: newParams }));
                  dispatch(getOutdoors({ params: newParams }));
                  dispatch(getSinaleticas({ params: newParams }));
                }
              }}
              options={initialData.map((muppi) => {
                return {
                  value: muppi.serie,
                  label: muppi.serie,
                };
              })}
            />
          </div>
        )}
        {/*rede */}
        {!_.isEmpty(initialData) && (
          <div className="group">
            <OptionSelect
              onRef={(ref) => {
                setRefNetworks(ref);
              }}
              placeholder="Nr Rede"
              onChangeChoices={async (data) => {
                if (!clear) {
                  console.log("aqui: ", data);
                  dispatch(setMapAdjust(true));
                  const newParams = {
                    ...params,
                    networks: data,
                  };
                  setParams(newParams);
                  dispatch(getMonopostes({ params: newParams }));
                  dispatch(getOutdoors({ params: newParams }));
                  dispatch(getSinaleticas({ params: newParams }));
                }
              }}
              options={_.uniqBy(
                initialData
                  .map((muppi) => {
                    return {
                      value: muppi.network,
                      label: muppi.network,
                    };
                  })
                  .filter((muppi) => muppi.value !== ""),
                "value"
              )}
            />
          </div>
        )}
        {/*dimensao */}
        {!_.isEmpty(initialData) && (
          <div className="group">
            <OptionSelect
              onRef={(ref) => {
                setRefDimensao(ref);
              }}
              placeholder="Dimensão"
              options={_.sortBy(
                _.uniqBy(
                  initialData
                    .map((muppi) => {
                      return {
                        value: muppi.dimensao,
                        label: muppi.dimensao,
                      };
                    })
                    .filter((muppi) => muppi.value && muppi.value !== ""),
                  "value"
                ),
                "value"
              )}
              onChangeChoices={async (data) => {
                if (!clear) {
                  dispatch(setMapAdjust(true));
                  const newParams = {
                    ...params,
                    dimensao: data,
                  };
                  setParams(newParams);
                  dispatch(getMonopostes({ params: newParams }));
                  dispatch(getOutdoors({ params: newParams }));
                  dispatch(getSinaleticas({ params: newParams }));
                }
              }}
            />
          </div>
        )}
        {/*data */}
        {!_.isEmpty(initialData) && (
          <div className="group" id="calendar">
            <div
              className="date start-date"
              onClick={() => {
                setDateIsOpen(!dateIsOpen);
              }}
            >
              <div>
                {startDate
                  ? dayjs(startDate).format("D MMM YYYY").toString()
                  : `Data de início`}
              </div>
              <div>
                <Caret />
              </div>
            </div>
            <div
              className="date end-date"
              onClick={() => {
                setDateIsOpen(!dateIsOpen);
              }}
            >
              <div>
                {endDate
                  ? dayjs(endDate).format("D MMM YYYY").toString()
                  : `Data de fim`}
              </div>
              <div>
                <Caret />
              </div>
            </div>
            <Popover
              className="search-popover"
              open={dateIsOpen}
              onClose={() => {
                setDateIsOpen(false);
                map.doubleClickZoom.enable();
                map.dragging.enable();
                map.scrollWheelZoom.enable();
                map.touchZoom.enable();
                map.boxZoom.enable();
                map.keyboard.enable();
                if (map.tap) map.tap.enable();
              }}
              anchorEl={document.querySelector("#calendar")}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <DatePicker
                minDate={today}
                selected={startDate ? new Date(startDate) : false}
                dateFormat="dd/MM/yyyy"
                onChange={handleChange}
                startDate={startDate ? new Date(startDate) : false}
                endDate={endDate ? new Date(endDate) : false}
                selectsRange
                inline
                isClearable
              />
            </Popover>
          </div>
        )}
        {startDate && endDate && (
          <div className="radio">
            <label htmlFor="disponiveis">
              <input
                type="checkbox"
                id="disponiveis"
                name="disponibilidade"
                checked={availability}
                onChange={async (e) => {
                  dispatch(setMapAdjust(false));
                  const newParams = {
                    ...params,
                    availability: e.target.checked ? "available" : "all",
                  };
                  setAvailability(e.target.checked);
                  setParams(newParams);
                  dispatch(getMonopostes({ params: newParams }));
                  dispatch(getOutdoors({ params: newParams }));
                  dispatch(getSinaleticas({ params: newParams }));
                }}
              />{" "}
              Apresentar equipamentos ocupados
            </label>
          </div>
        )}
        {((params.networks && !_.isEmpty(params.networks)) ||
          (params.series && !_.isEmpty(params.series)) ||
          (params.concelhos && !_.isEmpty(params.concelhos)) ||
          availability ||
          startDate ||
          endDate) && (
          <button
            className="clean_filters"
            onClick={async () => {
              await setClear(true);
              dispatch(setMapAdjust(true));
              setAvailability(false);
              dispatch(setStartDate(false));
              dispatch(setEndDate(false));
              refSeries.setValue([]);
              refNetworks.setValue([]);
              refConcelhos.setValue([]);
              refEquipment.setValue([]);
              refSituacao.setValue([]);
              refDimensao.setValue([]);
              dispatch(getMonopostes());
              dispatch(getOutdoors());
              dispatch(getSinaleticas());
              setClear(false);
              setParams({});
            }}
          >
            limpar filtros
          </button>
        )}
      </div>
    </div>
  );
}
