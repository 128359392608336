import EquipmentMap from "../../components/Map/EquipmentMap";
import Header from "../../components/Header/Header";

export default function View() {
  return (
    <>
      <Header />
      <EquipmentMap onlyView="true" />
    </>
  );
}
