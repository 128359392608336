import { useState } from "react";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { FiMap, FiMaximize } from "react-icons/fi";
import Modal from "@mui/material/Modal";
import { Icon } from "leaflet";
import { Marker, Popup } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import {
  addItem,
  addItems,
  removeItem,
  removeItems,
} from "../../lib/slices/cartSlice";
import { open } from "../../lib/slices/sidebarSlice";

import MuppiImage from "../../assets/images/M03.jpeg";
import BlueMarker from "../Map/images/sinaletica_blue.png";
import RedMarker from "../Map/images/sinaletica_red.png";
import GreenMarker from "../Map/images/sinaletica_green.png";
import OrangeMarker from "../Map/images/sinaletica_orange.png";
import CyanMarker from "../Map/images/sinaletica_cyan.png";
import GrayMarker from "../Map/images/sinaletica_gray.png";
import _ from "lodash";

import "./markerPopup.scss";

dayjs.extend(weekday);

function MarkerPopupSinaletica(props) {
  const { sinaletica, onlyView } = props;
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.value);
  const sinaleticas = useSelector((state) => state.sinaletica.value);
  const { startDate, endDate } = useSelector((state) => state.search);
  const [isOpen, setOpen] = useState(false);

  const markerBlueMarker = new Icon({
    iconUrl: BlueMarker,
    iconSize: [25, 30],
  });

  const markerRedMarker = new Icon({
    iconUrl: RedMarker,
    iconSize: [25, 30],
  });

  const makerGreenMarker = new Icon({
    iconUrl: GreenMarker,
    iconSize: [25, 30],
  });

  const markerOrangeMarker = new Icon({
    iconUrl: OrangeMarker,
    iconSize: [25, 30],
  });

  const markerCyanMarker = new Icon({
    iconUrl: CyanMarker,
    iconSize: [25, 30],
  });

  const markerGrayMarker = new Icon({
    iconUrl: GrayMarker,
    iconSize: [25, 30],
  });

  const handleIcon = (cart) => {
    let pin_blue = false;
    for (let i = 0; i < cart.length; i++) {
      pin_blue = sinaletica.options.F.some(
        (item) => item.sub_serie === cart[i].serie
      );
      if (pin_blue) break;
      pin_blue = sinaletica.options.C.some(
        (item) => item.sub_serie === cart[i].serie
      );
      if (pin_blue) break;
      pin_blue = sinaletica.options.L.some(
        (item) => item.sub_serie === cart[i].serie
      );
      if (pin_blue) break;
      pin_blue = sinaletica.options.LC.some(
        (item) => item.sub_serie === cart[i].serie
      );
      if (pin_blue) break;
    }
    return pin_blue
      ? markerBlueMarker
      : sinaletica.situacao === "rescindiu"
      ? markerOrangeMarker
      : sinaletica.situacao === "oferta"
      ? markerCyanMarker
      : sinaletica.situacao === "fantasma"
      ? markerGrayMarker
      : sinaletica.situacao === "reserva"
      ? markerRedMarker
      : sinaletica.isFree
      ? makerGreenMarker
      : markerRedMarker;
  };

  const handleAddCart = (sinaletica, item, all = false) => {
    if (!all) {
      const newSinaletica = {
        ...sinaletica,
        serie: item.sub_serie,
        _id: item._id,
        startDate: startDate
          ? dayjs(startDate).toJSON()
          : dayjs().weekday(3).toJSON(),
        endDate: endDate
          ? dayjs(endDate).toJSON()
          : dayjs().weekday(3).add(1, "week").toJSON(),
      };
      dispatch(addItem(newSinaletica));
    } else {
      let sinaleticasNetwork = _.filter(
        sinaleticas,
        (pin) => pin.network === sinaletica.network
      );
      sinaleticasNetwork = _.map(sinaleticasNetwork, (pin) => {
        return {
          ...pin,
          startDate: startDate
            ? dayjs(startDate).toJSON()
            : dayjs().weekday(3).toJSON(),
          endDate: endDate
            ? dayjs(endDate).toJSON()
            : dayjs().weekday(3).add(1, "week").toJSON(),
        };
      });
      dispatch(addItems(sinaleticasNetwork));
    }
    dispatch(open());
  };

  const handleRemoveCart = (sinaletica, all = false) => {
    if (!all) {
      dispatch(removeItem(sinaletica));
    } else {
      dispatch(
        removeItems(
          _.filter(sinaleticas, (pin) => pin.network === sinaletica.network)
        )
      );
    }
    dispatch(open());
  };

  return (
    <Marker
      position={[
        sinaletica.location.coordinates[0],
        sinaletica.location.coordinates[1],
      ]}
      icon={handleIcon(cart)}
      onTap={() => alert("open")}
    >
      <Popup>
        <div className="marker-popup">
          <div className="marker-popup__header">
            <div className="maximize_img_btn" onClick={() => setOpen(true)}>
              <FiMaximize stroke="#0181BA" size={16} />
            </div>
            <div className="image">
              <img src={MuppiImage} alt="Muppi Imagem" />
            </div>
            <a
              href={`http://maps.google.com/maps?q=&layer=c&cbll=${sinaletica.location.coordinates[0]},${sinaletica.location.coordinates[1]}`}
              target="_blank"
              className="street_view"
              rel="noreferrer"
            >
              <div className="street_view_link">
                Street view <FiMap stroke="#0181BA" />
              </div>
            </a>
          </div>
          <div className="marker-popup__content">
            {sinaletica.concelho && (
              <div className="marker-popup__content__item">
                <div className="title">Concelho: </div>
                <div className="value">{sinaletica.concelho}</div>
              </div>
            )}
            {sinaletica.serie && (
              <div className="marker-popup__content__item">
                <div className="title">Serie: </div>
                <div className="value">{sinaletica.serie}</div>
              </div>
            )}
            <div className="marker-popup__content__availability">
              {sinaletica.isFree ? (
                <div className="free">Livre para Utilização</div>
              ) : (
                <div className="busy">
                  Ocupado até {dayjs(sinaletica.until).format("DD/MM/YYYY")}
                </div>
              )}
            </div>
            {!onlyView && (
              <>
                <div className="marker-popup__content__buttons">
                  {sinaletica.options.F.length > 0 && (
                    <span className="sinaletica_direction">Frente</span>
                  )}
                  {sinaletica.options.F.map((item) => {
                    return cart.some((pin) => pin.serie === item.sub_serie) ? (
                      <button
                        key={item.sub_serie}
                        className="border_button"
                        onClick={() => handleRemoveCart(item)}
                      >
                        {`Remover ${item.sub_serie}`}
                      </button>
                    ) : (
                      <button
                        key={item.sub_serie}
                        className={`normal_button ${
                          item.free ? "" : "disabled"
                        }`}
                        onClick={() => {
                          handleAddCart(sinaletica, item);
                        }}
                      >
                        {`${
                          item.free
                            ? "Adicionar " + item.sub_serie
                            : "Ocupado " + item.sub_serie
                        }`}
                      </button>
                    );
                  })}
                  {sinaletica.options.C.length > 0 && (
                    <span className="sinaletica_direction">Costas</span>
                  )}
                  {sinaletica.options.C.map((item) => {
                    return cart.some((pin) => pin.serie === item.sub_serie) ? (
                      <button
                        key={item.sub_serie}
                        className="border_button"
                        onClick={() => handleRemoveCart(item)}
                      >
                        {`Remover ${item.sub_serie}`}
                      </button>
                    ) : (
                      <button
                        key={item.sub_serie}
                        className={`normal_button ${
                          item.free ? "" : "disabled"
                        }`}
                        onClick={() => {
                          handleAddCart(sinaletica, item);
                        }}
                      >
                        {`${
                          item.free
                            ? "Adicionar " + item.sub_serie
                            : "Ocupado " + item.sub_serie
                        }`}
                      </button>
                    );
                  })}
                  {sinaletica.options.L.length > 0 && (
                    <span className="sinaletica_direction">Lateral</span>
                  )}
                  {sinaletica.options.L.map((item) => {
                    return cart.some((pin) => pin.serie === item.sub_serie) ? (
                      <button
                        key={item.sub_serie}
                        className="border_button"
                        onClick={() => handleRemoveCart(item)}
                      >
                        {`Remover ${item.sub_serie}`}
                      </button>
                    ) : (
                      <button
                        key={item.sub_serie}
                        className={`normal_button ${
                          item.free ? "" : "disabled"
                        }`}
                        onClick={() => {
                          handleAddCart(sinaletica, item);
                        }}
                      >
                        {`${
                          item.free
                            ? "Adicionar " + item.sub_serie
                            : "Ocupado " + item.sub_serie
                        }`}
                      </button>
                    );
                  })}
                  {sinaletica.options.LC.length > 0 && (
                    <span className="sinaletica_direction">Lateral Costas</span>
                  )}
                  {sinaletica.options.LC.map((item) => {
                    return cart.some((pin) => pin.serie === item.sub_serie) ? (
                      <button
                        key={item.sub_serie}
                        className="border_button"
                        onClick={() => handleRemoveCart(item)}
                      >
                        {`Remover ${item.sub_serie}`}
                      </button>
                    ) : (
                      <button
                        key={item.sub_serie}
                        className={`normal_button ${
                          item.free ? "" : "disabled"
                        }`}
                        onClick={() => {
                          handleAddCart(sinaletica, item);
                        }}
                      >
                        {`${
                          item.free
                            ? "Adicionar " + item.sub_serie
                            : "Ocupado " + item.sub_serie
                        }`}
                      </button>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </Popup>
      <Modal
        open={isOpen}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="image-popup-show">
          <img src={MuppiImage} alt="Muppi Imagem" />
        </div>
      </Modal>
    </Marker>
  );
}

export default MarkerPopupSinaletica;
