import { MapContainer, TileLayer, useMap } from "react-leaflet";
import L from "leaflet";
import { lineString } from "@turf/helpers";
import bbox from "@turf/bbox";
import { useParams } from "react-router-dom";

import "./map.scss";
import "leaflet/dist/leaflet.css";
import MarkerPopupMonopostes from "../Marker-popup/MarkerPopupMonoposte";
import MarkerPopupOutdoors from "../Marker-popup/MarkerPopupOutdoor";
import MarkerPopupSinaleticas from "../Marker-popup/MarkerPopupSinaletica";
import Search from "../Search/SearchEquipments";
import { useAuth } from "../../providers/authProvider";
import { useSelector, useDispatch } from "react-redux";
import { getMonopostes } from "../../lib/slices/monoposteSlice";
import { getOutdoors } from "../../lib/slices/outdoorSlice";
import { getSinaleticas } from "../../lib/slices/sinaleticaSlice";
import _ from "lodash";
import Sidebar from "../Sidebar/Sidebar";
import Caption from "../Caption/Caption";

const AdjustMap = () => {
  const map = useMap();
  const dataMonopostes = useSelector((state) => state.monoposte.value);
  const dataOutdoors = useSelector((state) => state.outdoor.value);
  const dataSinaleticas = useSelector((state) => state.sinaletica.value);
  let data = [...dataMonopostes, ...dataOutdoors, ...dataSinaleticas];
  const coords = data.map((data) => {
    return data.location.coordinates;
  });
  if (coords.length > 1) {
    const line = lineString(coords);
    const all_bounds = bbox(line);
    if (map) {
      map.fitBounds(
        L.latLngBounds(
          L.latLng(all_bounds[0], all_bounds[1]),
          L.latLng(all_bounds[2], all_bounds[3])
        ),
        { padding: [50, 50] }
      );
    }
  } else {
    if (map) {
      if (coords.length > 0) {
        map.setView([coords[0][0], coords[0][1]], 16);
      } else {
        map.panTo([39.557191, -7.8536599]);
        map.setZoom(7);
      }
    }
  }
  return null;
};
export default function EquipmentMap(props) {
  const monopostes = useSelector((state) => state.monoposte);
  const outdoors = useSelector((state) => state.outdoor);
  const sinaleticas = useSelector((state) => state.sinaletica);
  const { adjustMap } = useSelector((state) => state.search);
  const dispatch = useDispatch();
  const auth = useAuth();
  const { hash } = useParams();

  return (
    <MapContainer
      zoomControl={false}
      center={[39.557191, -7.8536599]}
      zoom={7}
      scrollWheelZoom={true}
    >
      {adjustMap && <AdjustMap />}
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Search />
      {(!_.isEmpty(monopostes.value) ||
        !_.isEmpty(outdoors.value) ||
        !_.isEmpty(sinaleticas.value)) && (
        <>
          {monopostes.value.map((monoposte) => {
            return (
              monoposte.location.coordinates[0] &&
              monoposte.location.coordinates[1] && (
                <MarkerPopupMonopostes
                  monoposte={monoposte}
                  onlyView={props.onlyView}
                  key={monoposte._id}
                />
              )
            );
          })}
          {outdoors.value.map((outdoor) => {
            return (
              outdoor.location.coordinates[0] &&
              outdoor.location.coordinates[1] && (
                <MarkerPopupOutdoors
                  outdoor={outdoor}
                  onlyView={props.onlyView}
                  key={outdoor._id}
                />
              )
            );
          })}
          {sinaleticas.value.map((sinaletica) => {
            return (
              sinaletica.location.coordinates[0] &&
              sinaletica.location.coordinates[1] && (
                <MarkerPopupSinaleticas
                  sinaletica={sinaletica}
                  onlyView={props.onlyView}
                  key={sinaletica._id}
                />
              )
            );
          })}
        </>
      )}
      {auth.user && <Sidebar equipmentPage={true} />}
      {auth.user && <Caption />}
    </MapContainer>
  );
}
