import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import api from "../axios";

export const getMonopostes = createAsyncThunk(
  "monopostes/all",
  async (options = {}) => {
    const { params, hash } = options;
    const endpoint = hash ? `/order/monopostes/${hash}` : "/map/monopostes";
    if (
      params?.equipment?.length > 0 &&
      !params?.equipment?.includes("monoposte")
    )
      return [];
    const response = await api({
      url: endpoint,
      method: "get",
      params,
    });
    if (response.statusText !== "OK") {
      throw new Error("Failed to fetch monopostes");
    }
    const data = await response.data;
    return data;
  }
);

export const monopostesSlice = createSlice({
  name: "monopostes",
  initialState: {
    value: [],
    initialValue: [],
  },
  reducers: {
    addItems: (state, action) => {
      state.value = action.payload;
      if (_.isEmpty(state.initialValue)) {
        state.initialValue = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMonopostes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMonopostes.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
        if (_.isEmpty(state.initialValue)) {
          state.initialValue = action.payload;
        }
      })
      .addCase(getMonopostes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export const { addItems, changeItem } = monopostesSlice.actions;

export default monopostesSlice.reducer;
